.section-title__container {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
}

.section-title{
	display: flex;
	flex-direction: column;
	align-items: center;
}

.section-title h2{
	font-family: var(--title-font);
	font-size: var(--title-font-size);
	text-transform: uppercase;
}

.section__title--border {
	height: 10px;
	width: 4rem;
	background-color: white;
	margin-bottom: 1.5rem;
}

.section__title--border.blue {
	background-color: var(--main-color);
}

@media screen and (min-width: 55rem) {
	.section-title__container {
		align-items: flex-start;
		max-width: 45rem;
	}
	.section-title__container.full-width {
		max-width: 100%;
	}
	.section-title{
		align-items: flex-start;
	}
}