.collages__container .grouped-list__group--items{
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));
    grid-template-rows: auto;
    grid-gap: 1rem;
}

.collage-gallery_item{
	box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.2);
	border-radius: var(--border-radius-md);
	text-decoration: none;
	color: inherit;
	transition: box-shadow 0.3s, transform 0.3s;
    padding: var(--s0);
    cursor: pointer;
    display: flex;
    flex-direction: column;
    gap: var(--s0);
    text-align: center;
    position: relative;
}

.collage-gallery_item:hover{
	transform: scale(1.02);
}

.collage-gallery_item h4{
    font-family: var(--title-font);
    font-size: var(--large-font-size);
}

.collage-gallery_item img{
    border-radius: var(--border-radius-sm);
}

.collage-gallery_item-admin{
    cursor: default;
}

.collage-gallery_item-admin:hover{
    transform: none;
}

.collage-buttons{
    display: flex;
	gap: var(--s0);
}

.collage-buttons_button.verwijderen {
	background-color: var(--error-color);
}

.collage-buttons_button.content{
    background-color: var(--positive-color);
}

.collage-active{
    color: var(--positive-color);
    font-size: var(--small-font-size);
    font-style: italic;
}

.collage-inactive{
    color: var(--error-color);
}