.inputnumber{
    font-family: inherit !important;
    font-size: inherit !important;
}

.invoice-details{
    margin-top: var(--s0);
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: var(--s-1);
}

.invoice-detail-row {
    display: contents;
}

.invoice-detail-label {
    font-weight: bold;
}

.invoice-detail-value {
    word-break: break-word;
    font-weight: normal;
}

.open-statistics-button{
    background-color: var(--positive-color);
}

.chart-container{
    margin-top: var(--s0);
}

@media (max-width: 700px) {
    .table td.invoice-name{
        font-weight: bold;
        border-bottom: none;
        padding-bottom: 0;
    }

    .table td.invoice-amount{
        font-style: italic;
        padding-top: var(--s-1);
    }
}


.streepkes-table th{
    font-weight: bold;
}

.streepkes-table .remove-leader{
    cursor: pointer;
    font-weight: bold;
    font-size: 20px;
}

.streepkes-table thead{
    border-bottom: 2px solid var(--main-color);
}

.streepkes-table th, .streepkes-table td{
    border-bottom: 1px solid var(--main-color);
}

.streepkes-table td{
    border-right: 1px solid var(--main-color);
}

.streepkes-table td:not(.leader-label){
    max-width: 100px;
    overflow: hidden;
}


.streepkes-table td.total{
    background-color: var(--main-color);
    color: white;
}

.streepkes-table .leader-label{
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 300px;
}

.streepkes-table td{
    padding: var(--s-1);
}

.streepkes-table .streepkes-input{
    outline: none;
    border: none;
    padding: 0;
}

.streepkes-table .streepkes-input:focus{
    outline: none;
    box-shadow: none;
}

.streepkes-table .p-inputtext {
    padding: 0;
    border: none;
    outline: none;
    box-shadow: none;
    max-width: 100px;
}