/*   HERO SECTION   */
.hero__section {
	height: 60vh;
	margin-bottom: 16rem;
}
.blueBG {
	background-color: var(--main-color);
	color: white;
}
.hero__images--container {
	height: 100%;
	width: 100%;
	z-index: 10;
	overflow: hidden;
	position: relative;
}
.hero__image {
	opacity: 0;
	width: 100%;
	height: 100%;
	position: absolute;
	transition: opacity 0.5s ease-in-out;
}
.hero__image.active {
	opacity: 1;
}
.hero__image img{
	width: 100%;
	height: 100%;
	object-fit: cover;
}
.hero__section svg {
	z-index: 10;
	align-self: end;
	position: relative;
}
.hero__section svg .d {
    fill: #fff;
}
.hero__section svg .top {
	transform: translateY(1px)
}
.hero__section .down {
	transform: translateY(-1px)
}
.hero__wrapper {
	height: 100%;
	position: relative;
}
@media screen and (min-width: 55rem) {
	.hero__section svg .d {
		fill: var(--main-color);
	}

	.hero__section {
		height: 82vh;
		margin-bottom: 9rem;
	}

	.secondSVG {
		display: none;
	}
}
/*   HERO CONTENT   */
.hero__content {
	background-color: white;
	color: var(--main-color);
	z-index: 10;
	align-self: end;
	text-align: center;
	display: flex;
	flex-direction: column;
	gap: var(--s0);
	padding: 1.5rem 1rem 2rem 1rem;
}
.hero__content h2 {
	font-family: var(--title-font);
	line-height: 0.8;
	font-size: var(--title-font-size);
}
.hero__content p {
	font-size: var(--large-font-size);
}
@media screen and (min-width: 55rem) {
	.hero__content {
		margin: 0 auto;
		position: relative;
		bottom: 9rem;
		max-width: 600px;
		border-radius: var(--s3);
		padding: 1.5rem 4rem 2rem 4rem;
		box-shadow: 0 0 1rem rgba(0, 0, 0, 0.4);
	}

	.hero__content h2 {
		font-size: 4.5rem;
	}
}

/* REST OF HOMEPAGE */
.homepage__container {
	margin-top: var(--s0);
	margin-bottom: var(--s2);
}
.intro__section{
	padding: var(--s0);
	padding-top: var(--s2);
	padding-bottom: 5rem;
}
.more__link{
	color: white;
	font-size: var(--small-font-size);
}


.home__section--content {
	display: flex;
	flex-direction: column;
	gap: var(--s1)
}

@media screen and (min-width: 55rem) {
	.home__section--container {
		text-align: left;
	}

	.home__section--text .section-title__container {
		align-items: flex-start;
	}
}

.takken__section--container{
	border-top: 0.2rem solid var(--main-color);
	margin-top: var(--s1);
	padding-top: var(--s3);
}

@media screen and (max-width: 400px){
	.hero__section svg.down{
		top: -3px;
	}
}