.working-year-group {
  display: flex;
  flex-direction: column;
  gap: var(--s0);
}

.working-year-group h3 {
  font-family: var(--title-font);
  font-size: var(--subtitle-font-size);
}

.working-year-item {
  padding: var(--s0);
  border: 2px solid var(--main-color);
}

.working-year-item .name {
  font-weight: bold;
}

.working-year-item__info {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: var(--s3);
  margin-top: var(--s-1);
}

.working-year-item__info-item{
  display: flex;
  gap: var(--s-1);
}

@media screen and (min-width: 55rem) {
  .working-year-item__info {
    justify-content: flex-start;
  }
}