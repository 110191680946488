
.registrationstable-header{
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-end;
}

@media screen and (min-width: 820px){
    .registrationstable-header{
        width: 50%;
    }
}

.exportToExcel__container{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: var(--s-1);
    font-style: italic;
    cursor: pointer;
}

.exportToExcel__button{
    background-color: var(--positive-color);
}

.popup-text{
    margin-bottom: 1rem;
}