.label.file{
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
}

.form-group.images{
    flex-direction: column;
}

.changeMedia-button{
    background-color: var(--positive-color) !important;
}

.upload-form{
    align-items: center;
}

@media screen and (min-width: 55rem) {
    .upload-form{
        align-items: flex-start;
    }
}

.collage-types{
    display: flex;
    flex-direction: column;
    gap: var(--s0);
}

.collage-type{
    padding: var(--s0);
    border: 2px solid var(--main-color);
    border-radius: var(--border-radius-md);
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: var(--s0);
}

.collage-type .pi{
    cursor: pointer;
}

.collage-type input{
    outline: none;
    border: none;
    padding: 0;
}

.collage-type-buttons{
    display: flex;
    gap: var(--s0);
}