.events-list{
	display: grid;
	grid-template-columns: 1fr;
	gap: var(--s1);
}

.event-list_item {
	height: 100%;
	box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.2);
	border-radius: var(--border-radius-md);
	text-decoration: none;
	color: inherit;
	overflow: hidden;
	transition: box-shadow 0.3s, transform 0.3s;
}

.event-list_item:not(.admin):hover {
	transform: scale(1.02);
}

.event-list_item--image img {
	width: 100%;
	object-fit: cover;
}

.event-list_item--body{
	padding: 10px var(--s0);
	flex: 1 1 auto;
}

.event-list_item h3 {
	font-family: var(--title-font);
	font-size: var(--large-font-size);
	text-decoration: none;
	color: inherit;
	margin-bottom: var(--s0);
}

.event-buttons{
	display: flex;
	gap: var(--s0);
	padding: var(--s0);
	padding-top: 10px;
}

.event-buttons_button.verwijderen {
	background-color: var(--error-color);
}

@media screen and (min-width: 600px){
	.event-list_item{
		display: flex;
		align-items: flex-start;
	}

	.event-list_item .event-list_item--body{
		padding: 1rem;
		text-align: left;
	}

	.event-buttons{
		flex-direction: column;
		height: 100%;
		justify-content: center;
	}

	.event-list_item img{
		width: unset;
		height: 100%;
		width: 100%;
	}

	.event-list_item--image{
		flex: 0 0 auto;
		overflow: hidden;
		width: 180px;
		height: 100%;
	}
}

@media screen and (min-width: 55rem) {
    .events-list {
		grid-template-columns: repeat(3, 1fr);
    }
	.events-list_admin .event-list_item{
		min-width: 320px;
	}
	.event-list_item{
		flex-direction: column;
		align-items: unset;
	}
	.event-list_item img{
		width: 100%;
	}
	.event-list_item .event-list_item--body{
		padding: 10px var(--s0);
	}
	.event-buttons {
		flex-direction: row;
		height: unset;
	}
	.event-list_item--image{
		flex: unset;
		overflow: hidden;
		width: unset;
		height: 180px;
	}
}

@media screen and (min-width: 80rem) {
	.events-list_admin{
		grid-template-columns: repeat(4, 1fr);
	}
}