.eventDetail-container {
    gap: var(--s1);
    display: flex;
    flex-direction: column-reverse;
    padding-bottom: 4rem;
    padding-top: 1rem;
}

.eventDetail-container img {
    max-width: 90%;
    max-height: 100%;
}

.event__content {
    margin-top: 1rem;
}

.eventDetail-container .info-side {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

@media screen and (min-width: 55rem) {
    .eventDetail-container {
        display: grid;
        grid-template-columns: 50% 50%;
    }
}