/* FORM & GROUP STYLING */
.form {
    display: flex;
    flex-direction: column;
    gap: var(--s0);
}

.form .form-group {
    display: flex;
    gap: var(--s0);
}

form .form-group__half {
    display: flex;
    width: 100%;
    gap: var(--s0);
}

@media screen and (max-width: 740px) {
    form .form-group {
        flex-wrap: wrap;
    }
}

/* LABEL & ERROR STYLING */
.form .label {
    font-weight: bold;
    display: flex;
    flex-wrap: wrap;
    gap: 0.2rem;
    width: 100%;
    align-self: flex-start;
}

.form .label.flex-column {
    flex-direction: column;
    align-items: flex-start;
}

.form .error-message {
    position: absolute;
    visibility: hidden;
    opacity: 0;
}

.form .label.error .error-message {
    position: relative;
    visibility: visible;
    opacity: 1;
    pointer-events: initial;
    text-align: left;
    font-style: italic;
    font-size: var(--small-font-size);
}

.form .label.error .input {
    border: var(--s-2) solid var(--error-color);
    outline: none;
}

/* INPUT STYLING */
.form .input {
    padding: var(--s-1);
    width: 100%;
    outline: var(--s-99) solid var(--main-color);
    border: var(--s-2) solid transparent;
    border-radius: var(--border-radius-sm);
    transition: box-shadow 0.1s ease-in-out;
}

.form .input:focus {
    box-shadow: 0 0 2px 2px rgb(94, 162, 224);
}

.form .label.error .input:focus {
    box-shadow: 0 0 2px 2px rgb(255, 154, 154);
}

.form .input::placeholder {
    color: var(--main-color);
    opacity: 0.5;
}

/* CHECKBOX STYLING */
.form .checkbox {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    width: 2rem;
    height: 2rem;
    cursor: pointer;
}

/* DISABLED STYLING */
.form.disabled {
    pointer-events: none;
}

.form.disabled .input {
    background-color: rgb(228, 228, 228);
    outline: var(--s-99) solid gray;
    color: gray;
}

.form .input:disabled {
    background-color: rgb(228, 228, 228);
    outline: var(--s-99) solid gray;
    color: gray;
}

.label.disabled {
    color: gray;
}

/* VARIOUS STYLING */
.form select.input option {
    cursor: pointer;
}

form .label .required {
    color: var(--error-color);
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}

/* AUTOCOMPLETE STYLING */
.form .autocomplete-wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
    outline: var(--s-99) solid var(--main-color);
    border-radius: var(--border-radius-sm);
    position: relative;
}

.form .autocomplete-input{
    width: 100%;
    padding: var(--s-1);
    border: var(--s-2) solid transparent;
    outline: none;
}

.form.disabled .autocomplete-wrapper{
    outline: var(--s-99) solid gray;
    background-color: rgb(228, 228, 228);
}

.form.disabled .autocomplete-input {
    color: gray;
    background-color: rgb(228, 228, 228);
}

.form .autocomplete-dropdown{
    height: 100%;
    padding: 0 var(--s-1);
    background-color: var(--main-color);
    display: grid;
    place-items: center;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
}

.form .autocomplete-dropdown:hover{
    background-color: var(--darker-main-color);
}

.form .autocomplete-suggestions{
    position: absolute;
    top: 100%;
    width: 100%;
    max-height: 10rem;
    overflow-y: auto;
    background-color: white;
    z-index: 1;
    box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.4);
}

.form .autocomplete-suggestion{
	padding: var(--s-1);
	border: 2px solid var(--main-color);
	border-top: none;
    transition: background-color 0.2s ease-in-out;
}

.form .autocomplete-suggestion:not(.no-suggestions){
    cursor: pointer;
}

.form .autocomplete-suggestion:not(.no-suggestions):hover{
    background-color: rgb(185, 210, 255);
}

.form .autocomplete-suggestion:first-child{
    border-top: 2px solid var(--main-color);
}

.autocomplete-values{
    display: flex;
    gap: var(--s-1);
    align-self: center;
    padding: var(--s-1);
}

.autocomplete-value{
    background-color: var(--main-color);
    color: white;
    padding: var(--s-2) var(--s-1);
    border-radius: var(--border-radius-lg);
    display: flex;
    gap: var(--s-1);
}

.autocomplete-value-delete{
    cursor: pointer;
}

.form .error-image{
    color: var(--error-color);
}

.form .image-uploader{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: var(--s-1);
    position: relative;
}

.form .image-uploader .image-preview__container{
    display: grid;
    place-items: center;
}

.form .image-uploader .image-preview-icon{
    cursor: pointer;
}

.form .image-uploader .image-preview {
    display: none;
    position: absolute;
    top: 100%;
    right: 0;
    width: auto;
    max-width: 100%;
    max-height: 10rem;
    object-fit: contain;
    overflow-y: auto;
    background-color: white;
    z-index: 1;
    box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.4);
}

.form .image-uploader .image-preview__container:hover .image-preview {
    display: block;
}