.takken__container{
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: var(--s1);
}

.takken-list{
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: var(--s1);
	width: 40%;
	margin: 0 auto;
}

.takken-list_item{
	display: flex;
	flex-direction: column;
	align-items: center;
	font-family: var(--title-font);
	font-size: var(--large-font-size);
	cursor: pointer;
	transition: transform 0.2s ease-in-out;
	width: 100%;
	text-decoration: none;
	color: inherit;
}

.takken-list_item:hover {
	transform: scale(1.05);
}

.takken-list_item img{
	width: 90%;
	margin-bottom: 0.5rem;
	-webkit-filter: drop-shadow(0px 0px 5px #222);
	filter: drop-shadow(0px 0px 5px #d1d1d1);
}

.tak__info{
	text-align: center;
	margin: 0 auto;
	max-width: 80%;
	max-height: 0;
	overflow: hidden;
	transition: max-height 0.4s ease-in-out;
}

.tak__info.active{
	max-height: 800px;
	margin-bottom: var(--s2);
}

.tak__info-tile{
	font-family: var(--title-font);
	font-size: var(--subtitle-font-size);
}

.tak__info-ages{
	font-weight: bold;
	margin-bottom: var(--s0);
}

@media screen and (min-width: 55rem) {
	.takken-list{
		flex-direction: row;
		width: 100%;
		margin: unset;
	}
}