.leader {
  padding: var(--s0);
  box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.2);
  border-radius: var(--border-radius-lg);
  display: grid;
  gap: var(--s1);
  grid-template-columns: auto 1fr auto;
  text-align: left;
}

.leader img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}
.leader-info{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.leader h4 {
  font-family: var(--title-font);
  font-size: var(--large-font-size);
}

.leaders{
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: var(--s0);
}

.leader.admin {
  cursor: pointer;
  transition: transform 0.3s;
  min-width: 530px;
}

.leader.admin:hover {
  transform: scale(1.03);
}

.leader.admin .change-role{
  align-self: center;
  display: flex;
  flex-direction: row;
}

@media screen and (max-width: 55rem) {
  .leaders {
    grid-template-columns: 1fr;
  }

  .leaders {
    grid-template-columns: 1fr;
  }
}