.leader-groups{
    margin-top: var(--s0);
    text-align: left;
}

.leader-groups .leader-groups-title{
    font-family: var(--title-font);
    font-size: var(--subtitle-font-size);
    padding-bottom: 0 !important;
}

.leader-groups .leader-group{
    display: flex;
    flex-wrap: wrap;
    gap: var(--s0);
    margin: var(--s0) 0;
    border-radius: 2px solid var(--main-color);
}

@media screen and (max-width: 55rem) {
    .leaders__section-content .grouped-list__group--items{
        grid-template-columns: 1fr;
    }
}