.dashboard-top{
    display: grid;
    grid-template-columns: 1fr auto;
}

.dashboard-grid{
    display: grid;
    grid-template-columns: 1fr;    gap: 1rem;
}

.dashboard-title{
    font-family: var(--title-font);
    font-size: var(--subtitle-font-size);
    margin-bottom: var(--s-1);
}

.dashboard-card{
    padding: 1rem;
    border: 5px solid var(--darker-main-color);
    background-color: var(--main-color);
    border-radius: var(--border-radius-lg);
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
    color: white;
    text-decoration: none;
    font-weight: bold;
    font-size: var(--large-font-size);
    transition: transform 0.2s ease-in-out;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.7);
    cursor: pointer;
}

.dashboard-card:hover{
    transform: scale(1.02);
}

@media screen and (min-width: 55rem) {
    .dashboard-grid{
        grid-template-columns: 1fr 1fr;
    }
}