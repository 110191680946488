/*   APP CSS: Algemene styling classes die voor elke pagina kan terugkomen   */
.inherit-font {
	font-family: inherit;
	color: inherit;
	font-size: inherit;
}

strong {
	font-weight: bold;
}

.cursive{
	color: inherit;
	text-decoration: underline;
	font-style: italic;
}
a.cursive{
	overflow: hidden;
	text-overflow: ellipsis;
	cursor: pointer;
}

.layered-grid {
	display: grid;
	place-items: center;
}
.layered-grid>* {
	grid-row: 1;
	grid-column: 1;
}

/* GENERAL PAGE STYLING */
.page__container {
	color: var(--main-color);
	margin-top: 8rem;
	text-align: center;
	padding: var(--s0);
}
.page__container.admin{
	margin-top: 4rem;
}
.page__section {
	max-width: 60rem;
	margin: 0 auto;
}
.page__section:not(:first-child) {
	margin-top: var(--s3);
}
.page__section--content{
	border-top: 0.2rem solid var(--main-color);
	margin: var(--s2) 0;
	padding: 2rem 0;
}
@media screen and (min-width: 55rem) {
	.page__container {
		text-align: left;
	}
	.page__section {
		align-items: flex-start;
	}
}

/* ERROR STYLING */
.error{
	color: var(--error-color);
}
.error.delete-icon{
	margin-left: var(--s-1);
	cursor: pointer;
}

/* FLEX CONTAINER */
.flex__container {
	display: flex;
	gap: var(--s2);
	align-items: flex-start;
	flex-direction: column-reverse;
	text-align: center;
}
.flex__container img {
	width: 85%;
	max-width: 400px;
	align-self: center;
}
.flex__container--text {
	display: flex;
	flex-direction: column;
	gap: var(--s-1);
}
@media screen and (min-width: 55rem) {
	.flex__container {
		flex-direction: row;
		text-align: left;
	}
	.flex__container .section-title__container {
		align-items: flex-start;
	}
}

.round-image {
	border-radius: 100%;
}