.image-list{
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    grid-template-rows: auto;
    gap: 1rem;
    justify-items: center;
}

@media screen and (min-width: 880px){
    .image-list{
        justify-items: start;
    }
}

.image-list .image{
    object-fit: cover;
    width: 100%;
    border-radius: var(--border-radius-lg);
    transition: filter 0.3s;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.image-list .image:hover{
    cursor: pointer;
    filter: brightness(0.8);
}

.link-back{
    margin-bottom: 1rem;
}

.overlay-media{
    padding: 3rem !important;
    padding-top: 106px !important;
    text-align: center !important;
    display: unset !important;
    overflow-y: auto !important;
}

.detail__image{
    border-radius: var(--border-radius-lg);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    max-height: 100%;
    max-width: 600px;
}

.close-media{
    position: absolute;
    top: calc(1rem + 78px);
    right: 1.8rem;
    color: white;
    cursor: pointer;
    font-size: 60px;
}

.delete-media{
    position: absolute;
    top: calc(6rem + 78px);
    right: 1.5rem;
    color: white;
    cursor: pointer;
    font-size: 40px;
}