.media__search-options {
	display: flex;
	flex-direction: column;
	gap: 1rem;
	align-items: flex-start;
	margin: 1rem 0;
}

.filter-media-form{
	flex-wrap: wrap;
	height: 0rem;
	overflow-y: hidden;
	padding: 0 1px;
	transition: height 0.3s ease;
	padding: 0 5px;
}

.filter-media-form.show{
	height: 4.8rem;
}

.media__search-options--admincontainer {
    flex-basis: 100%;
	margin-top: -1rem;
}

@media screen and (max-width: 740px) {
	.filter-media-form.show{
		height: 250px;
	}
}
