.form__container{
	margin: var(--s2) 0;
	margin-bottom: var(--s1);
    display: flex;
    align-items: flex-end;
    justify-content: center;
    flex-wrap: wrap-reverse;
    gap: var(--s1);
}

.contact-items{
	text-align: center;
}

@media screen and (min-width: 55rem) {
	.contact-items{
		text-align: left;
	}
}