.adminLayoutWrapper{
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: 1fr;
    margin-top: 80px;
    color: var(--main-color);
    text-align: center;
}

.container-admin{
	text-align: center;
	padding: var(--s0);
    color: var(--main-color);
    display: flex;
    flex-direction: column;
    gap: var(--s1);
}

.admin__actions {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    align-items: center;
    gap: var(--s0);
}

.past-events {
    background-color: var(--positive-color);
}

.upcoming-events {
	background-color: var(--error-color);
}

@media screen and (min-width: 55rem) {
    .adminLayoutWrapper {
        grid-template-columns: auto 1fr;
        text-align: left;
        margin-top: 55px;
    }
    .container-admin {
        padding: 2rem;
        text-align: left;
    }
    .admin__actions {
        justify-content: flex-start;
    }
}
