/*   INDEX CSS: Algemene styling voor hele site (vooral variabelen en declaraties van fonts)   */

@font-face {
	font-family: Rubik;
	font-weight: normal;
	src: url('assets/fonts/Rubik-Regular.ttf');
}

@font-face {
	font-family: Rubik;
	font-weight: bold;
	src: url('assets/fonts/Rubik-Bold.ttf');
}

@font-face {
	font-family: PassionOne;
	font-weight: normal;
	src: url('assets/fonts/PassionOne.ttf');
}

*,
*::before,
*::after {
	box-sizing: border-box;
}

html {
	height: 100%;
	scroll-behavior: smooth;
	box-sizing: inherit;
}

:root {
	--s-99: .1rem;
	--s-2: .2rem;
	--s-1: .5rem;
	--s0: 1rem;
	--s1: 1.5rem;
	--s2: 2rem;
	--s3: 3rem;

	--lighter-main-color: #d7edfa;
	--light-main-color: #69a7e1;
	--main-color: #1576D1;
	--darker-main-color: #0a62b4;
	--error-color: #ce1010;
	--positive-color: #0f8a0f;
	--title-font: PassionOne, Rubik, Helvetica, sans-serif;
	--main-font: 'Rubik', Arial, Helvetica, sans-serif;

	--border-radius-sm: 5px;
	--border-radius-md: 0.5rem;
	--border-radius-lg: 1rem;

	--small-font-size: 1rem;
	--default-font-size: 1.2rem;
	--large-font-size: 1.5rem;
	--subtitle-font-size: 1.8rem;
	--title-font-size: 3rem;
}

#root {
	line-height: 1.2;
	font-family: var(--main-font);
	font-size: var(--default-font-size);
	scroll-behavior: smooth;
}

.page-root {
	min-height: 100vh;
	display: grid;
	grid-template-rows: 1fr;
}

.no-scroll {
    overflow: hidden;
}

.p-inputtext{
    border: var(--s-2) solid transparent;
    outline: var(--s-99) solid var(--main-color);
    font-family: inherit;
    font-size: inherit;
    color: inherit;
}